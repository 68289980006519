import React, { useState } from "react";
import axios from "axios";
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';
import 'bootstrap/dist/css/bootstrap.min.css';

// import {NavLink} from "react-router-dom";
import "./style.css";

const Home = (props) => {
    const [searchedDomain, setSearchedDomain] = useState("");
    const [domMsg, setDomMsg] = useState("");
    const [domain_com, setDomain_com] = useState("");
    const [domain_co, setDomain_co] = useState("");
    const [domain_org, setDomain_org] = useState("");
    const [domain_net, setDomain_net] = useState("");
    const [domain_edu, setDomain_edu] = useState("");
    const [domain_in, setDomain_in] = useState("");

    // const getIt = () => {
    //     localStorage.setItem("domainSearched", `searchedDomain`);
    //     props.history.push("./login");
    // };

    let domainName = '';
    const filterDomain = () => {
        if (searchedDomain.includes(' ')) {
            for (let i = 0; i < searchedDomain.length; i++) {
                if (searchedDomain[i] !== " "){
                    domainName = domainName + searchedDomain[i];
                }
            }
            if (! searchedDomain.includes('.')) {
                domainName = domainName + '.com';
            }
        }
        else if (! searchedDomain.includes('.')) {
            domainName = searchedDomain + '.com';
        }
        else{
            domainName = searchedDomain;
        }
        // setDomMsg(domainName)
    };

    async function checkReDomainAvailability(domainWithNewTLD) {
        // evt.preventDefault();

        const options = {
            method: 'GET',
            url: 'https://domain-checker-with-ai.p.rapidapi.com/domain/validate',
            params: {
              domain: domainWithNewTLD
            },
            headers: {
              'X-RapidAPI-Key': '2f050f83f7mshd5e59dda3f95233p1e7683jsn08c3ef4bd64d',
              'X-RapidAPI-Host': 'domain-checker-with-ai.p.rapidapi.com'
            }
        };
          
        try {
            const response = await axios.request(options);
            if (response.data.status){
                return(
                    `${domainWithNewTLD} is not available`
                );
                // setDomMsg(`The domain ${domainWithNewTLD} is already registered. We still can get it for you.`);
            }
            // console.log(response.data.status);

        // if (data.WhoisRecord && data.WhoisRecord.status === 'UNAVAILABLE') {
        //     setDomMsg(`The domain ${domain} is already registered. We still can get it for you.`)
        //     // console.log(`The domain ${domain} is already registered.`);
        // } else {
        //     setDomMsg(`Exact Domain matched! The domain ${domain} is available. Get it now.`);
        //     // console.log(`The domain ${domain} is available.`);
        // }
        } catch (error) {
            // console.error(error);
            return(`${domainWithNewTLD} is available for ₹499 for first year, and ₹999 from the second year.`);
            // setDomMsg(`Exact Domain matched! The domain ${domainWithNewTLD} is available. Get it now.`);
        }
    };

    async function checkDomainAvailability(evt) {
        setDomMsg('');
        evt.preventDefault();
        filterDomain();

        const options = {
            method: 'GET',
            url: 'https://domain-checker-with-ai.p.rapidapi.com/domain/validate',
            params: {
              domain: domainName
            },
            headers: {
              'X-RapidAPI-Key': '2f050f83f7mshd5e59dda3f95233p1e7683jsn08c3ef4bd64d',
              'X-RapidAPI-Host': 'domain-checker-with-ai.p.rapidapi.com'
            }
          };
          
        try {
            const response = await axios.request(options);
            if (response.data.status){
                setDomMsg(`The domain ${domainName} is already taken. We can still try to get it for you.`)

                if (domainName.includes('.com')) {
                    let domainNameWithoutTLD = domainName.replace(".com", "")
                    
                    let newDomain_co = domainNameWithoutTLD + '.co';
                    checkReDomainAvailability(newDomain_co)
                    .then(data => {
                        setDomain_co(data);
                    });

                    let newDomain_org = domainNameWithoutTLD + '.org';
                    checkReDomainAvailability(newDomain_org)
                    .then(data => {
                        setDomain_org(data);
                    });

                    let newDomain_net = domainNameWithoutTLD + '.net';
                    checkReDomainAvailability(newDomain_net)
                    .then(data => {
                        setDomain_net(data);
                    });

                    let newDomain_edu = domainNameWithoutTLD + '.edu';
                    checkReDomainAvailability(newDomain_edu)
                    .then(data => {
                        setDomain_edu(data);
                    });

                    let newDomain_in = domainNameWithoutTLD + '.in';
                    checkReDomainAvailability(newDomain_in)
                    .then(data => {
                        setDomain_in(data);
                    });
                };

                if (domainName.includes('.co')) {
                    let domainNameWithoutTLD = domainName.replace(".co", "")
                    
                    let newDomain_com = domainNameWithoutTLD + '.com';
                    checkReDomainAvailability(newDomain_com)
                    .then(data => {
                        setDomain_com(data);
                    });

                    let newDomain_org = domainNameWithoutTLD + '.org';
                    checkReDomainAvailability(newDomain_org)
                    .then(data => {
                        setDomain_org(data);
                    });

                    let newDomain_net = domainNameWithoutTLD + '.net';
                    checkReDomainAvailability(newDomain_net)
                    .then(data => {
                        setDomain_net(data);
                    });

                    let newDomain_edu = domainNameWithoutTLD + '.edu';
                    checkReDomainAvailability(newDomain_edu)
                    .then(data => {
                        setDomain_edu(data);
                    });

                    let newDomain_in = domainNameWithoutTLD + '.in';
                    checkReDomainAvailability(newDomain_in)
                    .then(data => {
                        setDomain_in(data);
                    });
                };

                if (domainName.includes('.org')) {
                    let domainNameWithoutTLD = domainName.replace(".org", "")
                    
                    let newDomain_com = domainNameWithoutTLD + '.com';
                    checkReDomainAvailability(newDomain_com)
                    .then(data => {
                        setDomain_com(data);
                    });

                    let newDomain_co = domainNameWithoutTLD + '.co';
                    checkReDomainAvailability(newDomain_co)
                    .then(data => {
                        setDomain_co(data);
                    });

                    let newDomain_net = domainNameWithoutTLD + '.net';
                    checkReDomainAvailability(newDomain_net)
                    .then(data => {
                        setDomain_net(data);
                    });

                    let newDomain_edu = domainNameWithoutTLD + '.edu';
                    checkReDomainAvailability(newDomain_edu)
                    .then(data => {
                        setDomain_edu(data);
                    });

                    let newDomain_in = domainNameWithoutTLD + '.in';
                    checkReDomainAvailability(newDomain_in)
                    .then(data => {
                        setDomain_in(data);
                    });
                };

                if (domainName.includes('.net')) {
                    let domainNameWithoutTLD = domainName.replace(".net", "")
                    
                    let newDomain_com = domainNameWithoutTLD + '.com';
                    checkReDomainAvailability(newDomain_com)
                    .then(data => {
                        setDomain_com(data);
                    });

                    let newDomain_co = domainNameWithoutTLD + '.co';
                    checkReDomainAvailability(newDomain_co)
                    .then(data => {
                        setDomain_co(data);
                    });

                    let newDomain_net = domainNameWithoutTLD + '.org';
                    checkReDomainAvailability(newDomain_net)
                    .then(data => {
                        setDomain_org(data);
                    });

                    let newDomain_edu = domainNameWithoutTLD + '.edu';
                    checkReDomainAvailability(newDomain_edu)
                    .then(data => {
                        setDomain_edu(data);
                    });

                    let newDomain_in = domainNameWithoutTLD + '.in';
                    checkReDomainAvailability(newDomain_in)
                    .then(data => {
                        setDomain_in(data);
                    });
                };

                if (domainName.includes('.edu')) {
                    let domainNameWithoutTLD = domainName.replace(".edu", "")
                    
                    let newDomain_com = domainNameWithoutTLD + '.com';
                    checkReDomainAvailability(newDomain_com)
                    .then(data => {
                        setDomain_com(data);
                    });

                    let newDomain_co = domainNameWithoutTLD + '.co';
                    checkReDomainAvailability(newDomain_co)
                    .then(data => {
                        setDomain_co(data);
                    });

                    let newDomain_org = domainNameWithoutTLD + '.org';
                    checkReDomainAvailability(newDomain_org)
                    .then(data => {
                        setDomain_org(data);
                    });

                    let newDomain_net = domainNameWithoutTLD + '.net';
                    checkReDomainAvailability(newDomain_net)
                    .then(data => {
                        setDomain_net(data);
                    });

                    let newDomain_in = domainNameWithoutTLD + '.in';
                    checkReDomainAvailability(newDomain_in)
                    .then(data => {
                        setDomain_in(data);
                    });
                };

                if (domainName.includes('.in')) {
                    let domainNameWithoutTLD = domainName.replace(".in", "")
                    
                    let newDomain_com = domainNameWithoutTLD + '.com';
                    checkReDomainAvailability(newDomain_com)
                    .then(data => {
                        setDomain_com(data);
                    });

                    let newDomain_co = domainNameWithoutTLD + '.co';
                    checkReDomainAvailability(newDomain_co)
                    .then(data => {
                        setDomain_co(data);
                    });

                    let newDomain_org = domainNameWithoutTLD + '.org';
                    checkReDomainAvailability(newDomain_org)
                    .then(data => {
                        setDomain_org(data);
                    });

                    let newDomain_net = domainNameWithoutTLD + '.net';
                    checkReDomainAvailability(newDomain_net)
                    .then(data => {
                        setDomain_net(data);
                    });

                    let newDomain_edu = domainNameWithoutTLD + '.edu';
                    checkReDomainAvailability(newDomain_edu)
                    .then(data => {
                        setDomain_edu(data);
                    });
                };
            }
            // console.log(response.data.status);

        // if (data.WhoisRecord && data.WhoisRecord.status === 'UNAVAILABLE') {
        //     setDomMsg(`The domain ${domain} is already registered. We still can get it for you.`)
        //     // console.log(`The domain ${domain} is already registered.`);
        // } else {
        //     setDomMsg(`Exact Domain matched! The domain ${domain} is available. Get it now.`);
        //     // console.log(`The domain ${domain} is available.`);
        // }
        } catch (error) {
            if (error.message.includes("Request failed with status code 429")){
                setDomMsg(`Error in checking domain availability. We're sorry, kindly try after some time.`);
            }
            else{
                setDomMsg(`Exact Domain matched! The domain ${domainName} is available. Get it now.`);
            }
            // setDomMsg('Error in checking domain availability. We\'re sorry, kindly try after some time.')
            // console.error('Error fetching WHOIS data:', error);
        }

        // -------------------------------------------
        // const domain = domainName;
        // Replace with your WHOIS API key
        // const apiKey = 'at_f0FvfLWTXoo45aUghMC9fo44yeSoG';
        // const apiUrl = `https://www.whoisxmlapi.com/whoisserver/WhoisService?apiKey=${apiKey}&domainName=${domain}&outputFormat=JSON`;
        // // const apiUrl = `https://api.jsonwhois.io/whois/domain?identifier=${domain}`;
        
        // // const axios = require('axios');



        // // Function to check domain availability
        // // async function checkDomainAvailability(domain) {
        // axios.get(`${apiUrl}`)
        //     .then(response => {
        //         const data = response.data;
        //         console.log(data.WhoisRecord);
        //         // console.log(data.WhoisRecord.administrativeContact.organization);
        //         // console.log(data.WhoisRecord.administrativeContact.country);
        //         // console.log(data.WhoisRecord.registryData.createdDate);
        //         // console.log(data.WhoisRecord.registryData.updatedDate);
        //         // console.log(data.WhoisRecord.registryData.expiresDate);
        //         // console.log(data.WhoisRecord.contactEmail);
        //         if (data.WhoisRecord && data.WhoisRecord.status === 'UNAVAILABLE') {
        //             setDomMsg(`The domain ${domain} is already registered. Still we can get it for you.`)
        //             // console.log(`The domain ${domain} is already registered.`);
        //         } else {
        //         setDomMsg(`Exact Domain matched! The domain ${domain} is available. Get it now.`);
        //         // console.log(`The domain ${domain} is available.`);
        //         }
        //     })
        //     .catch(error => {
        //         setDomMsg('Error in checking domain availability. We\'re sorry, kindly try after some time.')
        //         console.error('Error fetching WHOIS data:', error);
        //     });
        // -------------------------------------------------------------

        // try {
        //     const response = await axios.get(`${apiUrl}`);
            
        //     const data = response.data;
        //     domMsg(data);
        //     if (data.WhoisRecord && data.WhoisRecord.status === 'UNAVAILABLE') {
        //         setDomMsg(`The domain ${domain} is already registered. We still can get it for you.`)
        //         // console.log(`The domain ${domain} is already registered.`);
        //     } else {
        //     setDomMsg(`Exact Domain matched! The domain ${domain} is available. Get it now.`);
        //     // console.log(`The domain ${domain} is available.`);
        //     }
        // } catch (error) {
        //     setDomMsg('Error in checking domain availability. We\'re sorry, kindly try after some time.')
        //     console.error('Error fetching WHOIS data:', error);
        // }
        // }

        // Replace with the domain you want to check
        // const domainToCheck = 'example.com';
        // checkDomainAvailability(domainToCheck);






        // try {
        //     const response = await fetch(apiUrl, {
        //         headers: {
        //             'Authorization': `Token ${apiKey}`
        //         }
        //     });
            
        //     if (!response.ok) {
        //         throw new Error('Network response was not ok ' + response.statusText);
        //     }

        //     const data = await response.json();

        //     // Assume the API returns a JSON object with a field "available"
        //     if (data.available) {
        //         setDomMsg('Domain is available!')
        //         // document.getElementById('result').innerText = 'Domain is available!';
        //     } else {
        //         setDomMsg('Sorry! Domain is not available.')
        //         // document.getElementById('result').innerText = 'Domain is not available.';
        //     }
        // } catch (error) {
        //     console.error('There was a problem with the fetch operation:', error);
        //     setDomMsg('Error in checking domain availability. We\'re sorry, kindly try after some time.')
        //     // document.getElementById('result').innerText = 'Error checking domain availability.';
        //     // setDomMsg('Error in checking domain availability. or We\'re sorry, but something went wrong.')
        //     // setDomMsg('We are experiencing heavy traffic. Kindly try after some time.')
        // }
    };

    // function domSearch (evt) {
    //     evt.preventDefault();

    //     // const filterDomain = () => {
    //     //     if (searchedDomain.includes(' ')) {
    //     //         for (let i = 0; i < searchedDomain.length; i++) {
    //     //             if (searchedDomain[i] !== " "){
    //     //                 domainName = domainName + searchedDomain[i];
    //     //             }
    //     //         }
    //     //         if (! searchedDomain.includes('.')) {
    //     //             domainName = domainName + '.com';
    //     //         }
    //     //     }
    //     //     else if (! searchedDomain.includes('.')) {
    //     //         domainName = searchedDomain + '.com';
    //     //     }
    //     //     else{
    //     //         domainName = searchedDomain;
    //     //     }
    //     //     // setDomMsg(domainName)
    //     // }


    //     if ((domainName === "service.com") || (domainName === "www.service.com")) {
    //         setDomMsg("This DOMAIN is already taken! We may still arrange it for you.")
    //     }
    //     else {
    //         // setDomMsg("Congrats! Your desired domain is AVAILABLE.")
    //         setDomMsg(domainName)
    //     }

    // };

    return(
        <div>
            <div onSubmit={checkDomainAvailability} className="domainPage">
                <samp className="domainPageContainer">Get your best suited DOMAIN!</samp>
                <form className="domainSearchForm">
                    <InputGroup className="mb-3">
                        <Form.Control placeholder="Find your domain name" value={searchedDomain} onChange={(e) => setSearchedDomain(e.target.value)} required autoFocus className="searchDomainBox" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                        <input type="submit" value="Check It" className="domainBtn"></input>
                        {/* <Button variant="outline-secondary" id="button-addon2" className="domainBtn">
                        Check It
                        </Button> */}
                    </InputGroup>


                    {/* <input type="text" placeholder="Type your desired domain name" value={searchedDomain} onChange={(e) => setSearchedDomain(e.target.value)} required autoFocus className="searchBox"></input> */}
                    {/* <input type="text" placeholder="Find your domain here"></input> */}
                    {/* <br />
                    <input type="submit" value="Check It" className="btn"></input>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="button" value="Get It" onClick={getIt} className="btn"></input> */}
                </form>
                
                <p className="domMsg"><b>{domMsg}</b></p>

                <div>
                <Stack gap={3} style={{borderColor: 'red'}}>
                    <div className="p-2" >{domain_com}</div>
                    <div className="p-2" >{domain_co}</div>
                    <div className="p-2" >{domain_org}</div>
                    <div className="p-2" >{domain_net}</div>
                    <div className="p-2" >{domain_edu}</div>
                    <div className="p-2" >{domain_in}</div>
                    {/* <div className="p-2">Second item</div>
                    <div className="p-2">Third item</div>
                    <div className="p-2">Fourth item</div>
                    <div className="p-2">Fifth item</div>
                    <div className="p-2">Sixth item</div> */}
                </Stack>
                </div>
            </div>
        </div>
    )
};

export default Home;